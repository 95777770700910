import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const productHtml = ({data,location}) => (
	<Layout>
	  <Seo
		pagetitle="PRODUCT"
		pagedesc="MAZINの商品の紹介です。"
		pagepath={location.pathname}
	  />
<div className="container-fluid product-page">
  <div className="row">
    <div className="top-view">
      <div className="top-img bg-img_cover" />
      <div className="h1_wrap">
        <h1>製品一覧</h1>
      </div>{/*.h1_wrap*/}
      <a href="#scroll" id="scroll-button">
        <div className="scroll-down">
          <div>SCROLL</div><div />
        </div>
      </a>
    </div>{/*.top-view*/}
    <div id="scroll" className="contents">
      <div className="second-top bansou bg-img_cover black-filter">
        <div className="contents-title contents-title_white">BAN<br className="d-md-none" />SOU</div>
        <div className="contents-box">
          <h3 className="underline">伴走</h3><div />
          <div className="contents-comment">
            <div className="comment-br">
              今、これから先、工場が背負う根本的な問題は何なのか。
            </div>
            <div className="comment-br">
              AI/IoTなど、MAZINの製品は全て製造現場に特化し、様々な工場と伴走し開発されています。
            </div>
            <div className="comment-br">
              工場が、「物造り」にもっと想いやこだわりを注げるよう、MAZINは一歩先の課題を一つ一つ深く見極め、その荷物を少しでも軽くしたいと考えています。
            </div>
          </div>{/*.contents-comment*/}
        </div>{/*.contents-box*/}
      </div>{/*.second-top*/}
      <div className="voice">
        <div className="row">
            <div className="col-md-6 order-md-2">
                <div className="contents-box">
                  <h3 className="underline">声を磨く</h3><div />
                  <div className="contents-comment">
                    <div className="comment-br">
                      MAZINは、現状の製品に満足していません。
                    </div>
                    <div className="comment-br">
                      それは、この瞬間も実際に工場へ足を運び、「新しい現場の声」を常に製品へ反映し、磨きをかけ続けているからです。
                    </div>
                    <div className="comment-br">
                      そして、芝浦工業大学の澤 武一教授や、東京都産業研究所と一緒に、加工技術や射出成形機のIoT化など、独自の研究にも積極的に取り組んでいます。
                    </div>
                    <div className="comment-br">
                      全てはMAZINのミッションである、<br />
                      「強い工場を造る。」ために。
                    </div>
                  </div>{/*.contents-comment*/}
                  <div className="about-professor">
                    <div className="bg-img" />
                    <div className="professor">
                      <div className="underline">澤 武一 教授</div><div />
                      <div>
                        芝浦工業大学 デザイン工学部 デザイン工学科 教授。<br />
                        厚生労働省ものづくりマイスター認定。切削加工・研削加工・技能教育を専門分野に、「目で見てわかる研削盤作業」などの書籍を多数手がける。
                      </div>
                    </div>
                  </div>
                </div>{/*.contents-box*/}
            </div>
    
            <div className="col-md-6 order-md-1">
              <div className="voice-img bg-img_cover" />
            </div>
            
    
    
        </div>
      </div>{/*.third-top*/}
      {/*<div class="product-slide wide-slider">
              <div class="contents-title">PRODUCT</div>
					<div class="contents-comment">
						<h3 class="kakko underline">製品情報。</h3><div />
						伴走し、製品開発にご協力いただいた工場・導入企業様、メディア掲載情報（一部）。
					</div>
              <div class="contents-box">
                  <div class="slider_wrap">
                      <ul class="slider">
                          <li class="product-slide_1 bg-img_cover">Panasonic</li>
								<li class="product-slide_2 bg-img_cover">HONDA</li>
								<li class="product-slide_3 bg-img_cover">33333</li>
								<li class="product-slide_4 bg-img_cover">44444</li>
								<li class="product-slide_5 bg-img_cover">55555</li>
                      </ul>
                  </div><!--.slider_wrap*/}
      {/*<div class="slide-controller">
                      <div class="button"><div class="button-left"></div><div class="button-right"></div></div>
                      <div class="gauge">
                          <div class="gauge_0"><div></div></div>
                          <div class="gauge_1"><div></div></div>
                          <div class="gauge_2"><div></div></div>
                          <div class="gauge_3"><div></div></div>
                          <div class="gauge_4"><div></div></div>
                      </div>
                  </div><!--.slide-controller*/}
      {/*</div><!--.contents-box*/}
      {/*</div><!--.product-slide*/}
      <div className="contents-links">
        <div className="links-wrap">
            <Link to={`/sessaku/`}>
              <div className="link-wrap link-sessaku bg-img_cover black-filter">
                <div className="cat-title">PRODUCT 1</div>
                <div className="link-title"><div>切削強化</div></div>
                <div className="link-lead">切削加工業向け<br />IoTパッケージ</div>
                <div className="link-more">MORE<div className="bg-img" /></div>
              </div>
            </Link>
            <Link to={`/kumikomi/`}>
              <div className="link-wrap link-kumikomi bg-img_cover black-filter">
                <div className="cat-title">PRODUCT 2</div>
                <div className="link-title"><div>製品強化</div></div>
                <div className="link-lead">装置メーカー様向け<br />AI組込ソリューション</div>
                <div className="link-more">MORE<div className="bg-img" /></div>
              </div>
            </Link>
        </div>{/*.links-wrap*/}
        <div className="links-wrap">
            <Link to={`/kensaku/`}>
              <div className="link-wrap link-kensaku bg-img_cover black-filter">
                <div className="cat-title">PRODUCT 3</div>
                <div className="link-title"><div>工程強化</div></div>
                <div className="link-lead">製造工程<br />IoT基盤</div>
                <div className="link-more">MORE<div className="bg-img" /></div>
              </div>
            </Link>
          <div className="link-x_wrap">
            <div className="link-wrap link-x bg-img_cover black-filter">
              <div className="cat-title">PRODUCT X</div>
              <div className="link-title"><div>開発中</div></div>
              <div className="link-lead">「強い工場を造る。」<br />ために現在開発中</div>
            </div>
          </div>
        </div>{/*.links-wrap*/}
      </div>{/*.contents-links*/}
    </div>{/*.contents*/}
  </div>{/*.row*/}
</div>{/*.container-fluid*/}


	
	</Layout>

)

export default productHtml